@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SUIT-Regular";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GmarketSans";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff")
    format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GmarketSansMedium";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GongGothicBold";
  src: url("https://cdn.jsdelivr.net/gh/fontbee/font@main/Gonggames/GongGothicBold.woff")
    format("woff");
  font-weight: 700;
  font-style: normal;
}

:root {
  --background-color: #ffffff;

  --red-color: #ff2b00;
  --primary-color: #2683d0;
  --secondary-color: #18285e;
  --point-color: #ffff00;

  --gray-color: #eeeeee;
  --font-color: #595959;

  --header-height: 100px;
  --container-width: 1800px;
  --container: 80%;
  --input-height: 45px;

  --font-size-default: 18px;
  --f16: 16px;
  --f20: 20px;
  --f24: 24px;
  --f28: 28px;
  --f42: 46px;
  --f55: 55px;
}

body,
html {
  margin: 0;

  font-family: "SUIT-Regular", -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--font-color);
  background-color: var(--background-color);

  height: 100%;
  cursor: default;
  z-index: 0;

  scroll-behavior: smooth;

  word-break: keep-all;
}

html {
  font-size: var(--font-size-default);
}

p {
  font-size: var(--font-size-default);
  line-height: 1.6;
}

.gmarket {
  font-family: "GmarketSans", "SUIT-Regular", -apple-system, sans-serif;
}

.gmedium {
  font-family: "GmarketSansMedium", "SUIT-Regular", -apple-system, sans-serif;
}

.manru {
  font-family: "GongGothicBold";
}

.bold {
  font-weight: 900;
}

h1 {
  font-size: var(--f55);
  font-weight: bolder;
}

h2 {
  font-size: var(--f42);
  font-weight: bolder;
}

h3 {
  font-size: var(--f28);
  font-weight: bolder;
}

h4 {
  font-size: var(--f24);
  font-weight: bold;
}

h5 {
  font-size: var(--f20);
  font-weight: bold;
}

input[type="text"],
input[type="password"],
input[type="datetime-local"],
select {
  position: relative;
  width: 100%;
  height: var(--input-height);
  border: 1px solid var(--gray-color);
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

textarea {
  position: relative;
  width: 100%;
  height: calc(var(--input-height) * 4);
  border: 1px solid var(--gray-color);
  border-radius: 3px;
  padding: 10px;
  resize: none;
}

@media all and (min-width: 1024px) and (max-width: 1700px) {
  :root {
    --container: 88%;

    --f42: 38px;
  }
}

/* Tablet ( 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  :root {
    --divider-gap: 120px;

    --container: 88%;

    --f16: 15px;
    --f20: 18px;
    --f28: 27px;
    --f42: 32px;
    --f55: 42px;
  }
}

@media all and (max-width: 767px) {
  :root {
    --divider-gap: 60px;

    --input-height: 30px;
    --container: 90%;

    --font-size-default: 15px;
    --f16: 12px;
    --f20: 15px;
    --f24: 19px;
    --f28: 23px;
    --f42: 25px;
    --f55: 35px;
  }
}
