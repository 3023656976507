.txt_line {
  width: 100%;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#event-section1 {
  background-color: #18285e;
  background-image: url("../public/images/event-bg.png");
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

.text-shadow {
  text-shadow: -1.5px 0px var(--primary-color), 1.5px 2px var(--primary-color),
    1.5px 2px var(--primary-color), 0px -1.5px var(--primary-color);
}
